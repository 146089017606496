import humanizeDuration from 'humanize-duration'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import {
  getActivitiesLoading,
  setActivitiesLoading,
  startActivityLoading,
} from '../../actions/activitiesLoadingActions'
import { useStore } from '../../useStore'

interface LoadingBarProps {
  activityId: string
  description: string
  durationEstimate: number
}

export const LoadingBar = observer(
  ({ activityId, description, durationEstimate }: LoadingBarProps) => {
    const store = useStore()

    useEffect(() => {
      if (!getActivitiesLoading(store, activityId)) {
        setActivitiesLoading({
          store,
          activityId,
          durationEstimate,
          durationStartTime: Date.now(),
          durationElapsed: 0,
        })
      }

      startActivityLoading(store, activityId)
      //  eslint-disable-next-line react-hooks/exhaustive-deps -- deps are correct AK
    }, [])

    const activity = getActivitiesLoading(store, activityId)

    if (!activity) return null

    const { durationElapsed } = activity

    const percentageComplete = Math.min(
      (durationElapsed / durationEstimate) * 100,
      100
    )

    const timeRemaining = durationEstimate - durationElapsed

    const formatTimeRemaining = (seconds: number) => {
      if (seconds <= 60) {
        return '< 1 min. left'
      }

      return `${humanizeDuration(seconds * 1000, {
        largest: 1,
        round: true,
        units: ['m'],
        delimiter: ' ',
      }).replace('minutes', 'min.')} left`
    }

    return (
      <div className="loading-bar self-stretch">
        <div
          className="w-full bg-brd-neutral-secondary flex items-center my-1"
          style={{
            borderRadius: '10px',
            height: '6px',
            background: 'var(--Background-Neutral-Secondary, #F2F2F2)',
          }}
        >
          <div
            className="h-full bg-brd-brand-default"
            style={{
              width: `${percentageComplete}%`,
              borderRadius: '10px',
              transition: 'width 1s linear', // Smooth width transition
            }}
          />
        </div>
        <div className="flex flex-row justify-between mb-1">
          <span className="text-xs leading-[1.125rem]">{description}</span>
          <span className="text-xs leading-[1.125rem]">
            {formatTimeRemaining(Math.max(timeRemaining, 0))}
          </span>
        </div>
      </div>
    )
  }
)
