import Cookies from 'js-cookie'
import React, { useEffect } from 'react'

import ContentModal from './ContentModal'
import FlashMessages from './FlashMessages'
import Header from './Header'
import MainChat from './MainChat'
import Sidebar from './Sidebar'
import Workflow from './Workflow'
import { setMatters, setSelectedMatter } from '../actions/matterActions'
import StoreContext from '../StoreContext'
import { useStore } from '../useStore'

const ChatApp: React.FC = () => {
  const store = useStore()

  useEffect(() => {
    const initialize = async () => {
      await setMatters(store)

      redirectIfNotMaxChatUser()
      selectMatterFromCookie()
    }

    initialize()
  }, [])

  const selectMatterFromCookie = () => {
    const matterId = Cookies.get('last_selected_matter')
    const matter = store.matters.find((m) => m.id === matterId)
    if (matter && store.selectedMatter?.id !== matter.id) {
      setSelectedMatter(store, matter)
    }
  }

  const redirectIfNotMaxChatUser = () => {
    if (store.user?.enterprise?.is_on_max_chat_app === false) {
      window.location.href = store.credentials.customerAppUrl
    }
  }

  return (
    <StoreContext.Provider value={store}>
      <div className="flex flex-col h-screen overflow-hidden min-w-[1024px] max-w-[1920px] min-h-[720px] mx-auto">
        <Header />
        <div className="flex flex-1 h-screen overflow-y-auto">
          <Sidebar />
          <MainChat />
          <Workflow />
        </div>
        <ContentModal />
        <FlashMessages />
      </div>
    </StoreContext.Provider>
  )
}

export default ChatApp
