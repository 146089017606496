import { action, runInAction } from 'mobx'
import React from 'react'

import { fetchActivities } from '../apis/workflowActivitiesApi'
import { rollbarConfig } from '../rollbarConfig'

const setActivities = action(async (store) => {
  try {
    const activities = await fetchActivities(store.selectedMatter?.id)
    if (activities.length === 0) return

    runInAction(() => {
      store.activities = activities.map((activity) => ({
        ...activity,
        ref: React.createRef(),
      }))
    })
  } catch (error) {
    rollbarConfig(store)?.error(error)
  }
})

export { setActivities }
