import { observer } from 'mobx-react-lite'
import React, { useEffect, useState, useRef } from 'react'

import ChatInput from './ChatInput'
import ChatHeader from './main_chat/ChatHeader'
import ChatMessage from './main_chat/ChatMessage'
import ChatMessageSkeleton from './main_chat/ChatMessageSkeleton'
import { InitialChatSuggestions } from './main_chat/InitialChatSuggestions'
import LatestMessageButton from './main_chat/LatestMessageButton'
import { getChatResponseLoading } from '../actions/chatResponseLoadingActions'
import { getMessages, setMessages } from '../actions/messagesActions'
import { fetchChatHistory } from '../apis/chatApi'
import { useStore } from '../useStore'

const MainChat = observer(() => {
  const store = useStore()
  const chatContainerRef = useRef(null)

  const [loading, setLoading] = useState(true) // Setting it to false would bring back the broken scroll behaviour (pod1-549)
  const [containerWidth, setContainerWidth] = useState(0)

  const matterId = store.selectedMatter?.id
  const noMessagesOrFilesPresent =
    getMessages(store, matterId)?.length === 0 && !store.uploadedFiles.length

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: 'instant',
      })
    }
  }

  const loadChatHistory = async () => {
    try {
      const messages = await fetchChatHistory({
        chat_thread_message: {
          matter_id: matterId,
          thread_id: store.selectedMatter.thread_id,
        },
      })
      setMessages(store, matterId, messages)
    } catch (error) {
      console.error('Error fetching chat history:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (matterId) {
      loadChatHistory()
    } else {
      setMessages(store, matterId, [])
      setLoading(false)
    }
  }, [store.selectedMatter])

  const matterMessage = getMessages(store, matterId)
  const lastMessageLength = matterMessage?.at(-1)?.content?.length
  const matterMessageStoreLength = matterMessage?.length

  useEffect(() => {
    if (!loading) {
      scrollToBottom()
    }

    if (chatContainerRef.current) {
      setContainerWidth(chatContainerRef.current.offsetWidth)
    }
  }, [matterMessageStoreLength, lastMessageLength, loading])

  const renderMessages = () => {
    return matterMessage?.map((message) => (
      <ChatMessage
        key={message.id}
        message={message}
        mainChatContainerWidth={containerWidth}
      />
    ))
  }

  return (
    <div className="flex-1 flex flex-col relative">
      <ChatHeader />
      <div
        className="flex-1 pt-2 overflow-y-auto chat-container"
        ref={chatContainerRef}
      >
        {!loading && renderMessages()}
        {!loading && getChatResponseLoading(store, matterId) && (
          <ChatMessageSkeleton />
        )}
      </div>

      <div className="bg-white p-6 pb-2">
        {store.showLatestMessagesButton && (
          <LatestMessageButton scrollToBottom={scrollToBottom} store={store} />
        )}
        {noMessagesOrFilesPresent && <InitialChatSuggestions />}
        <ChatInput />
        <div className="mt-2 text-center text-xs text-txt-neutral-secondary">
          Alexi is a conversational tool designed to assist and provide
          information to the best of its abilities. Consider verifying important
          information.
        </div>
      </div>
    </div>
  )
})

export default MainChat
