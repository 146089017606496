import PropTypes from 'prop-types'
import React from 'react'

import DownloadIcon from '../assets/DownloadIcon'

const InstantMemoDownloadLink = ({ memoId }) => {
  const url = `/api/client/v1/instant_memos/${memoId}/pdf`

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="inline-block"
    >
      <button className="m-2 text-gray-400 hover:text-gray-600 w-6 h-6">
        <DownloadIcon classNames="w-6 h-6" />
      </button>
    </a>
  )
}

InstantMemoDownloadLink.propTypes = {
  memoId: PropTypes.string.isRequired,
}

export default InstantMemoDownloadLink
