import React from 'react'

const DocumentDOCX = () => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M86.6671 110H31.1116C28.1647 110 24.0837 110.085 22 108.001C19.9163 105.917 20.0005 101.836 20.0005 98.8894V21.1116C20.0005 18.1647 19.9163 14.0837 22 12C24.0837 9.91627 28.1647 10.0005 31.1116 10.0005H70.0005L97.7782 37.7782V98.8894C97.7782 101.836 97.8624 105.917 95.7787 108.001C93.695 110.085 89.614 110 86.6671 110Z"
      fill="#FAFAFA"
      stroke="#F2F2F2"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70 10V32.2222C70 33.6956 70.5853 35.1087 71.6272 36.1506C72.6691 37.1925 74.0821 37.7778 75.5556 37.7778H97.7778"
      stroke="#F2F2F2"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g opacity="0.6">
      <path
        d="M35.6077 62.9598C37.0877 62.9598 38.381 63.2531 39.4877 63.8398C40.5943 64.4264 41.4477 65.2531 42.0477 66.3198C42.661 67.3731 42.9677 68.5931 42.9677 69.9798C42.9677 71.3531 42.661 72.5731 42.0477 73.6398C41.4477 74.7064 40.5877 75.5331 39.4677 76.1198C38.361 76.7064 37.0743 76.9998 35.6077 76.9998H30.3477V62.9598H35.6077ZM35.3877 74.0398C36.681 74.0398 37.6877 73.6864 38.4077 72.9798C39.1277 72.2731 39.4877 71.2731 39.4877 69.9798C39.4877 68.6864 39.1277 67.6798 38.4077 66.9598C37.6877 66.2398 36.681 65.8798 35.3877 65.8798H33.7677V74.0398H35.3877Z"
        fill="#CECECE"
      />
      <path
        d="M51.5189 77.1398C50.1989 77.1398 48.9856 76.8331 47.8789 76.2198C46.7856 75.6064 45.9122 74.7531 45.2589 73.6598C44.6189 72.5531 44.2989 71.3131 44.2989 69.9398C44.2989 68.5664 44.6189 67.3331 45.2589 66.2398C45.9122 65.1464 46.7856 64.2931 47.8789 63.6798C48.9856 63.0664 50.1989 62.7598 51.5189 62.7598C52.8389 62.7598 54.0456 63.0664 55.1389 63.6798C56.2456 64.2931 57.1122 65.1464 57.7389 66.2398C58.3789 67.3331 58.6989 68.5664 58.6989 69.9398C58.6989 71.3131 58.3789 72.5531 57.7389 73.6598C57.0989 74.7531 56.2322 75.6064 55.1389 76.2198C54.0456 76.8331 52.8389 77.1398 51.5189 77.1398ZM51.5189 74.0198C52.6389 74.0198 53.5322 73.6464 54.1989 72.8998C54.8789 72.1531 55.2189 71.1664 55.2189 69.9398C55.2189 68.6998 54.8789 67.7131 54.1989 66.9798C53.5322 66.2331 52.6389 65.8598 51.5189 65.8598C50.3856 65.8598 49.4789 66.2264 48.7989 66.9598C48.1322 67.6931 47.7989 68.6864 47.7989 69.9398C47.7989 71.1798 48.1322 72.1731 48.7989 72.9198C49.4789 73.6531 50.3856 74.0198 51.5189 74.0198Z"
        fill="#CECECE"
      />
      <path
        d="M60.0216 69.9598C60.0216 68.5731 60.3216 67.3398 60.9216 66.2598C61.5216 65.1664 62.3549 64.3198 63.4216 63.7198C64.5016 63.1064 65.7216 62.7998 67.0816 62.7998C68.7482 62.7998 70.1749 63.2398 71.3616 64.1198C72.5482 64.9998 73.3416 66.1998 73.7416 67.7198H69.9816C69.7016 67.1331 69.3016 66.6864 68.7816 66.3798C68.2749 66.0731 67.6949 65.9198 67.0416 65.9198C65.9882 65.9198 65.1349 66.2864 64.4816 67.0198C63.8282 67.7531 63.5016 68.7331 63.5016 69.9598C63.5016 71.1864 63.8282 72.1664 64.4816 72.8998C65.1349 73.6331 65.9882 73.9998 67.0416 73.9998C67.6949 73.9998 68.2749 73.8464 68.7816 73.5398C69.3016 73.2331 69.7016 72.7864 69.9816 72.1998H73.7416C73.3416 73.7198 72.5482 74.9198 71.3616 75.7998C70.1749 76.6664 68.7482 77.0998 67.0816 77.0998C65.7216 77.0998 64.5016 76.7998 63.4216 76.1998C62.3549 75.5864 61.5216 74.7398 60.9216 73.6598C60.3216 72.5798 60.0216 71.3464 60.0216 69.9598Z"
        fill="#CECECE"
      />
      <path
        d="M84.4359 76.9998L81.5759 72.6998L79.0559 76.9998H75.1759L79.6759 69.8598L75.0759 62.9598H79.0559L81.8759 67.1998L84.3559 62.9598H88.2359L83.7759 70.0398L88.4159 76.9998H84.4359Z"
        fill="#CECECE"
      />
    </g>
  </svg>
)

export default DocumentDOCX
