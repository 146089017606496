import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'
import React from 'react'

import { removeFile } from '../../actions/fileActions'
import { useStore } from '../../useStore'
import CloseIcon from '../assets/CloseIcon'
import DocumentIcon from '../assets/DocumentIcon'
import { CircularLoader } from '../shared/CircularLoader'
import Tooltip from '../shared/Tooltip'

// Function to format file size
const formatFileSize = (sizeInBytes) => {
  const fileSizeInKB = sizeInBytes / 1024
  if (fileSizeInKB >= 1024) {
    return `${(fileSizeInKB / 1024).toFixed(1)} MB`
  } else {
    return `${fileSizeInKB.toFixed(1)} KB`
  }
}

const InputFileCard = ({ attachment }) => {
  const store = useStore()

  const handleRemoveFileClick = (localAttachment) => {
    removeFile(store, localAttachment.file.id)
  }

  return (
    <div
      key={attachment.file.id}
      className="relative p-3 border border-brd-neutral-default bg-bgCol-default-default rounded-lg group min-w-64"
    >
      <div className="flex">
        <div className="flex-shrink-0 p-2 bg-bgCol-neutral-default rounded">
          <DocumentIcon />
        </div>
        <div className="ml-2 flex-1">
          <Tooltip tooltipContents={attachment.filename} position="above">
            <p className="text-sm font-medium truncate w-40">
              {attachment.filename}
            </p>
          </Tooltip>
          {attachment.status === 'validated' && attachment.file.byte_size && (
            <p className="text-xs text-txt-default-secondary">
              {formatFileSize(attachment.file.byte_size)}
            </p>
          )}
        </div>
      </div>

      {attachment.status !== undefined &&
        attachment.status !== 'validated' &&
        attachment.status !== 'error' && (
          <div className="absolute -top-3 -right-3">
            <div className="flex justify-center items-center w-6 h-6 bg-bgCol-default-default rounded-3xl">
              <CircularLoader />
            </div>
          </div>
        )}

      {attachment.status === 'validated' && (
        <div className="absolute top-0 right-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <button
            onClick={() => handleRemoveFileClick(attachment)}
            type="button"
            className="absolute bottom-[-0.5rem] right-[-0.5rem] text-black-500 hover:black focus:outline-none"
          >
            <div className="bg-white p-[2px] shadow-sm rounded-md hover:bg-bgCol-default-defaultHover">
              <CloseIcon />
            </div>
          </button>
        </div>
      )}

      {attachment.status === 'error' && ( // Show FaTimes for both uploaded and error states
        <div className="flex">
          awd
          <div className="w-1/2 pt-1">
            <div className="absolute top-0 left-0 h-full bg-purple-500" />
            {attachment.error_msg}
          </div>
        </div>
      )}
    </div>
  )
}

InputFileCard.propTypes = {
  attachment: PropTypes.shape({
    content_type: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['uploading', 'uploaded', 'validated', 'error']),
    progress: PropTypes.any,
    error_msg: PropTypes.string,
    file: PropTypes.shape({
      id: PropTypes.string.isRequired,
      byte_size: PropTypes.number.isRequired,
    }).isRequired,
  }),
}

export default observer(InputFileCard)
