import React from 'react'

const DocumentJPG = () => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M86.6671 110H31.1116C28.1647 110 24.0837 110.085 22 108.001C19.9163 105.917 20.0005 101.836 20.0005 98.8894V21.1116C20.0005 18.1647 19.9163 14.0837 22 12C24.0837 9.91627 28.1647 10.0005 31.1116 10.0005H70.0005L97.7782 37.7782V98.8894C97.7782 101.836 97.8624 105.917 95.7787 108.001C93.695 110.085 89.614 110 86.6671 110Z"
      fill="#FAFAFA"
      stroke="#F2F2F2"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70 10V32.2222C70 33.6956 70.5853 35.1087 71.6272 36.1506C72.6691 37.1925 74.0821 37.7778 75.5556 37.7778H97.7778"
      stroke="#F2F2F2"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g opacity="0.6">
      <path
        d="M49.4028 62.9598V72.5198C49.4028 73.9998 48.9828 75.1398 48.1428 75.9398C47.3161 76.7398 46.1961 77.1398 44.7828 77.1398C43.3028 77.1398 42.1161 76.7198 41.2228 75.8798C40.3295 75.0398 39.8828 73.8465 39.8828 72.2998H43.2828C43.2828 72.8865 43.4028 73.3331 43.6428 73.6398C43.8828 73.9331 44.2295 74.0798 44.6828 74.0798C45.0961 74.0798 45.4161 73.9465 45.6428 73.6798C45.8695 73.4131 45.9828 73.0265 45.9828 72.5198V62.9598H49.4028Z"
        fill="#CECECE"
      />
      <path
        d="M62.8653 67.4798C62.8653 68.2931 62.6786 69.0398 62.3053 69.7198C61.932 70.3865 61.3586 70.9265 60.5853 71.3398C59.812 71.7531 58.852 71.9598 57.7053 71.9598H55.5853V76.9998H52.1653V62.9598H57.7053C58.8253 62.9598 59.772 63.1531 60.5453 63.5398C61.3186 63.9265 61.8986 64.4598 62.2853 65.1398C62.672 65.8198 62.8653 66.5998 62.8653 67.4798ZM57.4453 69.2398C58.0986 69.2398 58.5853 69.0865 58.9053 68.7798C59.2253 68.4731 59.3853 68.0398 59.3853 67.4798C59.3853 66.9198 59.2253 66.4865 58.9053 66.1798C58.5853 65.8731 58.0986 65.7198 57.4453 65.7198H55.5853V69.2398H57.4453Z"
        fill="#CECECE"
      />
      <path
        d="M73.9658 67.3998C73.7124 66.9331 73.3458 66.5798 72.8658 66.3398C72.3991 66.0865 71.8458 65.9598 71.2058 65.9598C70.0991 65.9598 69.2124 66.3265 68.5458 67.0598C67.8791 67.7798 67.5458 68.7465 67.5458 69.9598C67.5458 71.2531 67.8924 72.2665 68.5858 72.9998C69.2924 73.7198 70.2591 74.0798 71.4858 74.0798C72.3258 74.0798 73.0324 73.8665 73.6058 73.4398C74.1924 73.0131 74.6191 72.3998 74.8858 71.5998H70.5458V69.0798H77.9858V72.2598C77.7324 73.1131 77.2991 73.9065 76.6858 74.6398C76.0858 75.3731 75.3191 75.9665 74.3858 76.4198C73.4524 76.8731 72.3991 77.0998 71.2258 77.0998C69.8391 77.0998 68.5991 76.7998 67.5058 76.1998C66.4258 75.5865 65.5791 74.7398 64.9658 73.6598C64.3658 72.5798 64.0658 71.3465 64.0658 69.9598C64.0658 68.5731 64.3658 67.3398 64.9658 66.2598C65.5791 65.1665 66.4258 64.3198 67.5058 63.7198C68.5858 63.1065 69.8191 62.7998 71.2058 62.7998C72.8858 62.7998 74.2991 63.2065 75.4458 64.0198C76.6058 64.8331 77.3724 65.9598 77.7458 67.3998H73.9658Z"
        fill="#CECECE"
      />
    </g>
  </svg>
)

export default DocumentJPG
