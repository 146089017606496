// This component represent the initial chat header when the user first opens the chat or starts a new chat.

import PropTypes from 'prop-types'
import React from 'react'

import AlexiLogo from '../assets/alexiLogo'
import Accordion from '../shared/Accordion'

const InitialChatHeader = () => {
  return (
    <div className="flex px-6 pt-3 items-start justify-start">
      <div className="flex-none w-10 h-10 mr-3">
        <div className="flex justify-center items-center bg-bgCol-brand-default rounded-full w-10 h-10 border border-white shadow">
          <AlexiLogo color={'#ffffff'} width={16} height={16} />
        </div>
      </div>

      <div className="max-w-fit p-4 text-gray-700 items-center bg-bgCol-default-tertiary self-start rounded-r-lg rounded-bl-lg">
        <p className="text-sm">
          To begin, start asking questions. Alexi can help you issue spot,
          brainstorm, strategize, and draft correspondence, and can answer your
          legal questions. You can ask us anything from basic questions about
          the law to complex legal research questions requiring a full memo with
          citations.
        </p>
        <div className="py-1.5">
          <Accordion
            title="How can I generate a research memo?"
            body={
              <div>
                <p>
                  Research memos provide objective summaries of relevant case
                  law that you can use to inform your legal judgment. Each
                  research memo features linked authorities and supporting
                  citations that you can click to visit for further review. You
                  can request a research memo by asking Alexi to create one.
                </p>
                <p className="mt-1.5">
                  For example, <i>“Can you generate a memo for me?”</i> or{' '}
                  <i>“Please run a memo on this question.”</i>
                </p>
              </div>
            }
            open={false}
          />
          <Accordion
            title="What do I need to provide to create a research memo?"
            body={
              <div>
                <p>
                  Once requested, Alexi will ask you about the facts of your
                  client’s situation, confirm which question you want answered,
                  double check the applicable jurisdiction, and generate your
                  research memo for you.
                </p>
                <p className="mt-1.5">
                  For best results, research questions should be phrased
                  objectively and encompass one question at a time. While we
                  encourage you to provide facts to contextualize the research,
                  this is not required.
                </p>
                <p className="mt-1.5">
                  For example,{' '}
                  <i>
                    “How has the doctrine of estoppel been applied in paternity
                    proceedings?”
                  </i>
                </p>
              </div>
            }
            open={false}
          />
          <Accordion
            title="How long does it take to generate a research memo?"
            body={
              <div>
                <p>
                  Your research memo will be generated within approximately two
                  minutes. Feel free to keep chatting with Alexi in the
                  meantime.
                </p>
              </div>
            }
            open={false}
          />
        </div>
      </div>
    </div>
  )
}

InitialChatHeader.propTypes = {
  user: PropTypes.object.isRequired,
}

export default InitialChatHeader
