import PropTypes from 'prop-types'
import React from 'react'

import DownloadIcon from '../assets/DownloadIcon'

const LatestMessageButton = ({ store, scrollToBottom }) => {
  return (
    <div className="flex justify-center">
      <button
        onClick={() => {
          store.showLatestMessagesButton = false
          scrollToBottom()
        }}
        className="flex items-center space-x-2 bg-bgCol-brand-default text-white text-xs py-2 px-4 rounded-full shadow-lg mb-4"
      >
        <DownloadIcon stroke="#FAFAFA" />
        <span>Latest Messages</span>
      </button>
    </div>
  )
}

LatestMessageButton.propTypes = {
  store: PropTypes.shape({
    showLatestMessagesButton: PropTypes.bool,
  }),
  scrollToBottom: PropTypes.func.isRequired,
}
export default LatestMessageButton
