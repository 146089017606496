import { action } from 'mobx'

import { fetchFiles } from '../apis/filesApi'

const removeFile = action((store, blobId) => {
  store.uploadedFiles = store.uploadedFiles.filter(
    (attachment) => attachment.file.id !== blobId
  )
})

const setFetchedDocuments = action(async (store) => {
  store.documents = await fetchFiles(store)
})

export { removeFile, setFetchedDocuments }
