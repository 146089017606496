import { makeAutoObservable, computed, toJS } from 'mobx'
import React from 'react'

import { groupByDateAndThread } from './actions/workflowActivityActions'
import { UserInfo } from './apis/userApi'

class Store {
  // User-related Data
  user: UserInfo | null = null
  credentials: Credentials = {
    customerAppUrl: '',
  }

  // Input and Interaction
  inputValue = ''
  inputRef: any = null
  isInputFocused = true

  uploadedFiles = []

  // Matter-related Data
  matters: Matter[] = []
  selectedMatter: Matter | null = null
  activities: Activity[] = []
  activitiesLoading: ActivitiesLoading = {}
  documents = null

  // Messaging and Notifications
  chatResponseLoading = {}
  flashes = []
  lastProcessedIds = {}
  messageQueues = {}
  messages = {}
  isStreaming = {}

  // Channels
  matterChannels = {}
  activityChannels = {}

  userChannel = null // User channel message related

  showLatestMessagesButton = false
  highlightedMessageRef: null | React.RefObject<HTMLElement> = null
  highlightedActivityRef: null | React.RefObject<HTMLElement> = null

  // Workflow and Steps
  currentStep = 0
  workflowTab = 'activities' // 'activities' or 'documents'

  // Data Management
  sortParams = undefined
  filterParams = undefined
  manifest = null

  modal: ModalStore = {
    isOpen: false,
    isLoading: false,
    contentType: null,
    contentId: null,
    errorMessage: null,
    data: {},
  }

  constructor() {
    makeAutoObservable(this, {
      groupedActivities: computed,
    })

    const storedActivitiesLoading = localStorage.getItem('activitiesLoading')

    if (storedActivitiesLoading) {
      this.activitiesLoading = JSON.parse(storedActivitiesLoading)
    }
  }

  // Computed property for grouping activities by date and thread ID
  get groupedActivities(): {
    [date: string]: { [threadId: string]: Activity[] }
  } {
    return groupByDateAndThread(this.activities, this.selectedMatter)
  }
}

export function printStoreAttributes(store: { [x: string]: unknown }) {
  Object.keys(store).forEach((key) => {
    /* eslint-disable no-console */
    console.log(`${key}:`, toJS(store[key]))
  })
}

export default Store

export type { Store }

interface Activity {
  id: string
  created_at: string
  chat_thread_message_id: string
  [key: string]: any
}

interface ActivitiesLoading {
  [id: string]: {
    durationElapsed: number
    durationEstimate: number
    durationStartTime: number
  }
}

interface ModalStore {
  isOpen: boolean
  isLoading: boolean
  contentType: null | 'InstantMemo' | 'SummaryDocument'
  contentId: null | string
  errorMessage: null | string
  data: InstantMemoModalData | object
}

interface InstantMemoModalData {
  instant_memo: object
  instant_memo_html: string
}

interface Credentials {
  customerAppUrl: string
  rollbarAccessToken?: string
  rollbarEnvironment?: string
  hotjarId?: number
  hotjarVersion?: number
  googleAnalyticsId?: string
}

interface ChatThread {
  id: string
  name: string | null
  created_at: string
}

interface Matter {
  id: string
  assigned: boolean
  title: string
  gen_name: string | null
  user_id: number
  thread_id: string
  updated_at: string
  file_number: string | null
  created_at: string
  is_scheduled_for_deletion: boolean
  chat_threads: ChatThread[]
}
