import axios from 'axios'

// Interfaces for WorkflowActivity, Matter, and other related models
interface WorkflowActivity {
  id: string // UUID type
  activity_type: string
  created_at_formatted: string
  matter_id: string // UUID
  activity_type_details: string
  description: string
}

interface ApiResponse<T> {
  data: T
}

// The function that fetches activities related to a matter
export const fetchActivities = async (
  matterId: string
): Promise<WorkflowActivity[]> => {
  try {
    if (!matterId) return []

    const response: ApiResponse<WorkflowActivity[]> = await axios.get(
      `/api/client/v1/workflow_activities?matter_id=${matterId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
    )
    return response.data
  } catch (error) {
    console.error('Error fetching activities:', error)
    throw error
  }
}
