import { runInAction } from 'mobx'
import React from 'react'

import consumer from './consumer'
import addFlash from '../actions/AddFlash'

export const createActivityChannelSubscription = (store, matterId) => {
  return consumer.subscriptions.create(
    { channel: 'WorkflowActivityChannel', matter_id: matterId },
    {
      received(data) {
        runInAction(() => {
          const activityIndex = store.activities.findIndex(
            (activity) => activity.id === data.id
          )
          if (!data.ref) {
            data.ref = React.createRef()
          }

          if (activityIndex !== -1) {
            store.activities[activityIndex] = data
            if (
              data.activity_item?.status === 'FAILED' ||
              data.activity_item?.status === 'INVALID'
            ) {
              addFlash(
                store,
                'error',
                `We couldn't analyze ${data.activity_item?.description}. Please upload the document again.`
              )
            }
          } else {
            store.activities.push(data)
          }
        })
      },
    }
  )
}
