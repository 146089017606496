import { runInAction } from 'mobx'

import { fetchChatHistory } from './chatApi'
import { setMessages } from '../actions/messagesActions'

const updateMemoRequest = async (store, currentStep, data) => {
  const matterId = store.selectedMatter?.id

  const csrfToken = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content')
  const response = await fetch(
    `/api/client/v1/memo_requests/${store.selectedMatter.id}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      body: JSON.stringify({
        memo_request: {
          current_step: currentStep,
          data: data,
        },
      }),
    }
  )

  let res = await response.json()

  const messages = await fetchChatHistory({
    chat_thread_message: {
      matter_id: matterId,
      thread_id: store.selectedMatter.thread_id,
    },
  })

  setMessages(store, matterId, messages)

  runInAction(() => {
    store.currentStep = res.next_step
  })
}

export default updateMemoRequest
