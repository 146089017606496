import axios from 'axios'

import { rollbarConfig } from '../rollbarConfig'

async function getPresignedUrlAndMetadata({
  filename,
  byte_size,
  checksum,
  content_type,
  store,
  matter_id,
}) {
  try {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    }

    const response = await axios.post(
      '/api/client/v1/file_uploads',
      {
        blob: {
          filename,
          byte_size,
          checksum,
          content_type,
        },
        matter_id: matter_id,
      },
      {
        headers: headers,
      }
    )

    return response.data // Assuming your Rails backend returns { direct_upload: { ... }, blob: { ... } }
  } catch (error) {
    rollbarConfig(store)?.error(error)
    throw error
  }
}

const fetchFiles = async (store) => {
  try {
    const thread_id = store.selectedMatter.thread_id
    const response = await axios.get(
      `/api/client/v1/enterprise_attachments?thread_id=${thread_id}`
    )

    if (response.status === 200) {
      return response.data
    } else {
      return []
    }
  } catch (error) {
    rollbarConfig(store)?.error(error)
    throw error
  }
}

const validateFile = async (store, e_attachment_id, blob_id) => {
  const url = `/api/client/v1/file_uploads/validate_file?e_attachment_id=${e_attachment_id}&blob_id=${blob_id}`

  try {
    const { data, status } = await axios.get(url)

    if (status === 200) return data

    return { error_msg: data?.error_msg || 'Upload failed' }
  } catch (error) {
    const errorMsg = error?.response?.data?.error_msg || 'Upload failed'

    rollbarConfig(store)?.error(error)

    return { error_msg: errorMsg }
  }
}

const downloadFile = async (store, doc) => {
  try {
    const thread_id = store.selectedMatter.thread_id

    const response = await axios.get(
      `/api/client/v1/files/${doc.id}/download`,
      {
        params: {
          thread_id: thread_id,
          matter_id: store.selectedMatter.id,
        },
      }
    )

    if (response.status === 200) {
      // Api call to download relies on the disposition attachment header, returns a
      // downloadable S3 Url. Adding it to window.location.href will download the file
      window.location.href = response.data
    } else {
      return []
    }
  } catch (error) {
    rollbarConfig(store)?.error(error)
    throw error
  }
}

const deleteFile = async (store, doc) => {
  try {
    const thread_id = store.selectedMatter.thread_id
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
    const headers = {
      'X-CSRF-Token': csrfToken,
    }

    return await axios.delete(`/api/client/v1/files/${doc.id}/delete_file`, {
      params: {
        thread_id: thread_id,
        matter_id: store.selectedMatter.id,
      },
      headers: headers,
    })
  } catch (error) {
    rollbarConfig(store)?.error(error)
    throw error
  }
}

export {
  getPresignedUrlAndMetadata,
  fetchFiles,
  deleteFile,
  downloadFile,
  validateFile,
}
