import React from 'react'

import CircularLoadingIcon from '../assets/CircularLoadingIcon'

export const CircularLoader = () => {
  return (
    <div>
      <CircularLoadingIcon />
    </div>
  )
}
