import PropTypes from 'prop-types'
import React from 'react'

import MattersListItem from './MattersListItem'

const MattersList = ({
  groupTitle,
  matters,
  handleMatterClick,
  handleDeleteClick,
  selectedMatter,
}) => {
  if (matters.length === 0) return null

  return (
    <div>
      <div className="h-8 ml-2 pl-2 text-txt-default-tertiary text-xs leading-default content-center">
        {groupTitle}
      </div>
      <ul>
        {matters.map((matter, index) => (
          <MattersListItem
            key={index}
            matter={matter}
            isSelected={matter?.id === selectedMatter?.id}
            onMatterClick={handleMatterClick}
            onDeleteClick={handleDeleteClick}
          />
        ))}
      </ul>
    </div>
  )
}

MattersList.propTypes = {
  groupTitle: PropTypes.string.isRequired,
  matters: PropTypes.array.isRequired,
  handleMatterClick: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  selectedMatter: PropTypes.object,
}

export default MattersList
