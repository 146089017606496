import React from 'react'

const CircularLoadingIcon = () => {
  return (
    <svg
      className="animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <g clipPath="url(#clip0_5904_70325)">
        <path
          opacity="0.3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.49967 2.66665C7.08519 2.66665 5.72863 3.22855 4.72844 4.22874C3.72824 5.22894 3.16634 6.58549 3.16634 7.99998C3.16634 9.41447 3.72824 10.771 4.72844 11.7712C5.72863 12.7714 7.08519 13.3333 8.49967 13.3333C9.91416 13.3333 11.2707 12.7714 12.2709 11.7712C13.2711 10.771 13.833 9.41447 13.833 7.99998C13.833 6.58549 13.2711 5.22894 12.2709 4.22874C11.2707 3.22855 9.91416 2.66665 8.49967 2.66665ZM1.83301 7.99998C1.83301 4.31798 4.81767 1.33331 8.49967 1.33331C12.1817 1.33331 15.1663 4.31798 15.1663 7.99998C15.1663 11.682 12.1817 14.6666 8.49967 14.6666C4.81767 14.6666 1.83301 11.682 1.83301 7.99998Z"
          fill="#5133EA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.50031 2.66666C7.12527 2.66389 5.80284 3.19496 4.81164 4.14799C4.74843 4.20857 4.67391 4.25611 4.59233 4.28789C4.51075 4.31968 4.4237 4.33508 4.33617 4.33322C4.24863 4.33136 4.16232 4.31228 4.08216 4.27707C4.002 4.24186 3.92956 4.1912 3.86898 4.12799C3.80839 4.06478 3.76085 3.99026 3.72907 3.90867C3.69729 3.82709 3.68189 3.74005 3.68374 3.65251C3.6856 3.56498 3.70468 3.47867 3.7399 3.39851C3.77511 3.31835 3.82577 3.24591 3.88898 3.18532C5.12844 1.9944 6.78143 1.33052 8.50031 1.33332C8.67712 1.33332 8.84669 1.40356 8.97171 1.52858C9.09674 1.65361 9.16698 1.82318 9.16698 1.99999C9.16698 2.1768 9.09674 2.34637 8.97171 2.47139C8.84669 2.59642 8.67712 2.66666 8.50031 2.66666Z"
          fill="#5133EA"
        />
      </g>
      <defs>
        <clipPath id="clip0_5904_70325">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CircularLoadingIcon
