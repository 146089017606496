import { runInAction } from 'mobx'

import { findMessage } from './messagesActions'

// Function to remove the highlight from a ref
const removeHighlight = (store, ref) => {
  if (ref && ref.current) {
    ref.current.classList.remove(
      'bg-gradient-to-b',
      'from-[#F5F2FF]',
      'to-[#FFFFFF]',
      'px-2',
      'py-2'
    )
  }

  // Ensure that the store updates and clears the current highlighted refs
  runInAction(() => {
    store.highlightedMessageRef = null
    store.highlightedActivityRef = null
  })
}

// Function to highlight a new element
const highlightElement = (store, ref) => {
  if (ref && ref.current) {
    ref.current.classList.add(
      'bg-gradient-to-b',
      'from-[#F5F2FF]', // Starting color at the top (light purple)
      'to-[#FFFFFF]', // Ending color at the bottom
      'transition',
      'duration-500',
      'px-2',
      'py-2'
    )
    // Remove the gradient background after 5 seconds
    setTimeout(() => {
      removeHighlight(store, ref)
    }, 5000)
  }
}

// Function to scroll to an element
const scrollToElement = (ref) => {
  if (ref && ref.current) {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }
}

// Main function that handles scrolling and highlighting, un-highlighting the previous item
export const scrollAndHighlightMessage = (store, activity) => {
  const message = findMessage(
    store,
    store.selectedMatter.id,
    activity.chat_thread_message_id
  )

  // If there's a previously highlighted activity, remove its highlight
  if (store.highlightedActivityRef) {
    removeHighlight(store, store.highlightedActivityRef)
  }

  // If there's a previously highlighted message, remove its highlight
  if (store.highlightedMessageRef) {
    removeHighlight(store, store.highlightedMessageRef)
  }

  // Scroll to and highlight the new message
  scrollToElement(message.ref)
  highlightElement(store, message.ref)

  // Highlight the activity item
  highlightElement(store, activity.ref)

  // Update the store with the new highlighted message and activity refs
  runInAction(() => {
    store.highlightedMessageRef = message.ref
    store.highlightedActivityRef = activity.ref
  })
}
