import { marked } from 'marked'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import RemarkEmoji from 'remark-emoji'
import RemarkGfm from 'remark-gfm'

import DocSummaryMessage from './doc_summary/DocSummaryMessage'
import MemoMessage from './memoGeneration/MemoMessage'
import Step1 from './memoGeneration/Step1'
import Step2 from './memoGeneration/Step2'
import Step3 from './memoGeneration/Step3'
import Step3Confirmed from './memoGeneration/Step3Confirmed'
import StepConfirmed from './memoGeneration/StepConfirmed'
import updateMemoRequest from '../../apis/updateMemoRequest'
import { useStore } from '../../useStore'
import AlexiLogo from '../assets/alexiLogo'
import FailedFileCard from '../file_uploads/FailedFileCard'
import FileCard from '../file_uploads/FileCard'

const ChatMessage = ({ message, mainChatContainerWidth }) => {
  const store = useStore()

  const normalizeValue = (value) => {
    return value === 'none' || value === undefined || value === 'null'
      ? ''
      : value
  }

  const [researchFacts, setResearchFacts] = useState(
    normalizeValue(message.response_additional_context?.research_facts)
  )
  const [researchQuestion, setResearchQuestion] = useState(
    normalizeValue(message.response_additional_context?.research_question)
  )
  const [jurisdiction, setJurisdiction] = useState('')
  const [state, setState] = useState('')

  if (
    message.role === 'system' &&
    message.message_type === 'systemMessage' &&
    !message.content
  ) {
    return null
  }

  const handleSubmit = async (step, data) => {
    await updateMemoRequest(store, step, data)
  }

  const handleCancel = async (step) => {
    const data = { cancel: true }
    await updateMemoRequest(store, step, data)
  }

  const renderMemoStepContent = () => {
    switch (message.message_type) {
      case 'userMessage':
      case 'systemMessage':
      case 'memoStep1Cancelled':
      case 'memoStep2Cancelled':
      case 'memoStep3Cancelled':
        return renderChatContent(message)
      case 'memoStep1':
        return (
          <Step1
            inputValue={researchFacts}
            setInputValue={setResearchFacts}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
          />
        )
      case 'memoStep2':
        return (
          <Step2
            inputValue={researchQuestion}
            setInputValue={setResearchQuestion}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
          />
        )
      case 'memoStep3':
        return (
          <Step3
            jurisdiction={jurisdiction}
            setJurisdiction={setJurisdiction}
            state={state}
            setState={setState}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            additionalContext={message.response_additional_context}
          />
        )
      case 'memoStep1Confirmed':
        return (
          <StepConfirmed content={message.content} heading="Research facts" />
        )
      case 'memoStep2Confirmed':
        return (
          <StepConfirmed
            content={message.content}
            heading="Research question"
          />
        )
      case 'memoStep3Confirmed':
        return (
          <Step3Confirmed
            content={message.content}
            countryCode={store.user.enterprise.country_code}
          />
        )
      default:
        return null
    }
  }

  const isConfirmationOrCancellation = (msg) =>
    msg?.message_type?.includes('Confirm') ||
    msg?.message_type?.includes('Cancelled')

  const truncateAndAdjustHtml = (htmlContent) => {
    if (!htmlContent) {
      return null
    }

    // Check if htmlContent is a valid link or has no spaces
    const isLinkOrLongTextWithoutSpaces =
      /^(?:https?:\/\/)?(?:www\.)?([a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z0-9]{2,}$/i.test(
        htmlContent
      ) || !htmlContent.includes(' ')
    const maxChars =
      isLinkOrLongTextWithoutSpaces && mainChatContainerWidth > 50
        ? mainChatContainerWidth / 12
        : Infinity

    // Truncate the content if it exceeds maxLength
    if (htmlContent.length > maxChars) {
      return `${htmlContent.substring(0, maxChars - 3)}...`
    }

    return htmlContent
  }

  const verifyMarkdownContent = (msg) => {
    const renderedOutput = marked(msg)
    const strippedOutput = renderedOutput.replace(/(<([^>]+)>)/gi, '')
    return strippedOutput.trim() !== msg.trim()
  }

  const renderChatContent = (msg) => {
    const adjustedContent = truncateAndAdjustHtml(msg.content)

    return msg.role === 'system' &&
      msg.current_type === 'markdown' &&
      verifyMarkdownContent(msg.content) ? (
      <ReactMarkdown
        remarkPlugins={[[RemarkGfm], [RemarkEmoji]]}
        className={'no-tailwind markdown-content'}
        components={{
          // eslint-disable-next-line no-unused-vars,unused-imports/no-unused-vars
          a: ({ node, ...props }) => (
            <a {...props} target="_blank" rel="noopener noreferrer" />
          ),
        }}
      >
        {msg.content}
      </ReactMarkdown>
    ) : (
      <span dangerouslySetInnerHTML={{ __html: adjustedContent }} />
    )
  }

  const isMemoStepFormStyling = (msg) =>
    ['memoStep1', 'memoStep2'].includes(msg?.message_type)

  const isMemoCompletedStep = (msg) =>
    ['memoStep1Confirmed', 'memoStep2Confirmed', 'memoStep3Confirmed'].includes(
      msg?.message_type
    )

  const isMemoStep = (msg) => msg?.message_type?.includes('memoStep')

  const renderFileAttachments = (msg) =>
    msg?.enterprise_attachments
      ? msg.enterprise_attachments.map((attachment) => {
          if (
            attachment.status === 'FAILED' ||
            attachment.status === 'INVALID'
          ) {
            return (
              <FailedFileCard
                key={attachment.file.id}
                id={attachment.file.id}
                filename={attachment.filename}
                error={attachment?.error_msg}
              />
            )
          } else {
            return <FileCard key={attachment.file.id} attachment={attachment} />
          }
        })
      : null

  return (
    <div
      className={`flex p-6 pb-0 items-start ${
        message.role === 'user' ? 'justify-end' : 'justify-start'
      }`}
      ref={message.ref}
    >
      {message.role !== 'user' && (
        <div className="flex-none w-10 h-10 mr-3">
          <div className="flex justify-center items-center bg-bgCol-brand-default rounded-full w-10 h-10 border border-white shadow">
            <AlexiLogo color={'#ffffff'} width={16} height={16} />
          </div>
        </div>
      )}

      <div
        className={`
          py-3 px-4 text-gray-700 items-center
          ${isMemoStepFormStyling(message) ? 'w-full' : 'max-w-fit'}
          ${
            isMemoCompletedStep(message)
              ? 'border-brd-default-default rounded-t-none rounded-tr-2xl border-0.5 border-solid rounded-br-2xl rounded-bl-2xl'
              : message.role === 'user'
                ? 'bg-bgCol-default-secondary self-end rounded-l-lg rounded-br-lg'
                : 'bg-bgCol-default-tertiary self-start rounded-r-lg rounded-bl-lg'
          }
        `}
      >
        {message.message_type === 'memoResult' && (
          <MemoMessage message={message} />
        )}
        {message.message_type === 'docSummaryResult' && (
          <DocSummaryMessage message={message} />
        )}

        {!isConfirmationOrCancellation(message) && renderChatContent(message)}
        {isMemoStep(message) && renderMemoStepContent()}
        <div className="flex flex-wrap justify-end gap-4 max-w-[670px]">
          {renderFileAttachments(message)}
        </div>
      </div>

      {message.role === 'user' && (
        <div className="flex justify-center items-center uppercase bg-gray-50 rounded-full w-10 h-10 ml-3 border border-white shadow">
          <span className="text-lg font-semibold text-black">
            {store.user?.initials}
          </span>
        </div>
      )}
    </div>
  )
}

ChatMessage.propTypes = {
  message: PropTypes.shape({
    content: PropTypes.string,
    current_type: PropTypes.string.isRequired,
    enterprise_attachments: PropTypes.array,
    id: PropTypes.string,
    message_type: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOf([null]),
    ]),
    ref: PropTypes.object,
    role: PropTypes.string.isRequired,
    response_additional_context: PropTypes.shape({
      research_facts: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf([null]),
      ]),
      research_question: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf([null]),
      ]),
      jurisdiction: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf([null]),
      ]),
      state: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    }),
  }).isRequired,
  mainChatContainerWidth: PropTypes.number.isRequired,
}

export default ChatMessage
