import { action, runInAction } from 'mobx'

import Store from '../Store'

export const getActivitiesLoading = (store: Store, activityId: string) => {
  return store.activitiesLoading[activityId]
}

interface setActivitiesLoadingProps {
  store: Store
  activityId: string
  durationEstimate: number
  durationStartTime: number
  durationElapsed: number
}

export const setActivitiesLoading = action(
  ({
    store,
    activityId,
    durationEstimate,
    durationStartTime,
    durationElapsed,
  }: setActivitiesLoadingProps) => {
    store.activitiesLoading[activityId] = {
      durationEstimate,
      durationStartTime,
      durationElapsed,
    }

    saveActivitiesLoadingToLocalStorage(store)
  }
)

export const startActivityLoading = action(
  (store: Store, activityId: string) => {
    const activity = getActivitiesLoading(store, activityId)

    if (!activity) return

    const startTime = activity.durationStartTime
    const durationEstimate = activity.durationEstimate

    const intervalId = setInterval(() => {
      const now = Date.now()
      const elapsedSeconds = Math.floor((now - startTime) / 1000)

      runInAction(() => {
        if (elapsedSeconds >= durationEstimate) {
          clearInterval(intervalId)
          store.activitiesLoading[activityId].durationElapsed = durationEstimate
        } else {
          store.activitiesLoading[activityId].durationElapsed = elapsedSeconds
        }

        saveActivitiesLoadingToLocalStorage(store)
      })
    }, 1000)
  }
)

const saveActivitiesLoadingToLocalStorage = (store: Store) => {
  localStorage.setItem(
    'activitiesLoading',
    JSON.stringify(store.activitiesLoading)
  )
}
