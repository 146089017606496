import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import Tooltip from './Tooltip'
import { updateMatter } from '../../apis/mattersApi'
import { useStore } from '../../useStore'
import InfoIcon from '../assets/InfoIcon'
import XIcon from '../assets/XIcon'

const MatterDetailsForm = observer(({ matter, onClose }) => {
  const store = useStore()
  const [matterName, setMatterName] = useState(matter.title)
  const [fileNumber, setFileNumber] = useState(matter.file_number || '')
  const [errorMessage, setErrorMessage] = useState('')

  //format dates
  const { created_at, updated_at } = matter
  const dateFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' }
  const updatedAtDateTime = new Date(updated_at).toLocaleDateString(
    'en-US',
    dateFormatOptions
  )
  const createdAtDateTime = new Date(created_at).toLocaleDateString(
    'en-US',
    dateFormatOptions
  )

  const validateFileNumber = (value) => {
    if (value.length > 50) {
      setErrorMessage('More than 50 characters.')
      return false
    }
    setErrorMessage('')
    return true
  }

  const handleFileNumberChange = (e) => {
    const value = e.target.value
    setFileNumber(value)
    validateFileNumber(value)
  }

  const handleSave = async () => {
    if (!validateFileNumber(fileNumber)) {
      return
    }

    if (matter.id) {
      const updatedMatter = {
        ...matter,
        assigned_name: matterName,
        file_number: fileNumber.length > 0 ? fileNumber : null,
      }
      await updateMatter(store, updatedMatter)
    }
    onClose()
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white py-6 rounded-lg shadow-lg max-w-lg w-full text-txt-default-default">
        <div className="px-6 pb-4 flex justify-between items-center border-b border-brd-default-defaul">
          <h2 className="text-lg font-semibold">Matter Details</h2>
          <div onClick={onClose}>
            <XIcon />
          </div>
        </div>
        <div className="py-4 px-6">
          <label className="block mb-2 text-sm">Matter name</label>
          <input
            className="w-full p-2 border border-brd-default-default rounded mb-4"
            placeholder="Enter matter name"
            value={matterName}
            onChange={(e) => setMatterName(e.target.value)}
          />

          <label className="inline-flex items-center mb-2 text-sm">
            File number
            <Tooltip
              tooltipContents="No more than 50 characters"
              position="above"
            >
              <span className="ml-1 inline-flex items-center">
                <InfoIcon />
              </span>
            </Tooltip>
          </label>

          <input
            placeholder="Assign a file number to this matter"
            value={fileNumber}
            onChange={handleFileNumberChange}
            className={`border p-2 w-full ${
              errorMessage ? 'border-red-500' : 'border-brd-default-default'
            } rounded`}
          />
          {errorMessage && <p className="text-red-500 mt-1">{errorMessage}</p>}
        </div>
        <div className="px-6 pb-4 flex justify-between text-xs text-txt-default-secondary">
          <div>
            <div>
              Last modified by {store.user.full_name} on {updatedAtDateTime}
            </div>
            <div>
              Created by {store.user.full_name} on{' '}
              {new Date(createdAtDateTime).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </div>
          </div>
        </div>
        <div className="flex justify-end py-4 px-6">
          <button
            className="bg-bgCol-neutral-default text-black px-4 py-2 rounded mr-2"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-bgCol-brand-default text-white px-4 py-2 rounded"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
})

MatterDetailsForm.propTypes = {
  matter: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    fileNumber: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default MatterDetailsForm
