import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import React from 'react'

import ActivitiesColumn from './workflow/ActivitiesColumn'
import DocumentsColumn from './workflow/DocumentsColumn'
import EmptyDocumentsPlaceholder from './workflow/EmptyDocumentsPlaceholder'
import { useStore } from '../useStore'

const tabButtonClassName = (store, tab) => {
  return store.workflowTab === tab
    ? 'w-6/12 text-sm font-medium p-2 text-txt-brand-default border-b-2 border-txt-brand-default'
    : 'w-6/12 text-sm font-medium p-2'
}

const Workflow = observer(() => {
  const store = useStore()

  return (
    <div className="w-[23rem] bg-white border-l flex flex-col">
      <div className="px-4 pb-4 sticky">
        <div className="text-lg py-5 pb-2 font-semibold">Workflow</div>
        <div className="my-2 flex">
          <button
            className={tabButtonClassName(store, 'activities')}
            onClick={action(() => (store.workflowTab = 'activities'))}
          >
            Activity
          </button>
          <button
            className={tabButtonClassName(store, 'documents')}
            onClick={action(() => (store.workflowTab = 'documents'))}
          >
            Documents
          </button>
        </div>
      </div>

      {/* Scrollable content */}
      <div className="flex-1 overflow-y-auto overflow-x-clip ml-4">
        {store.workflowTab === 'activities' ? <ActivitiesColumn /> : null}
        {store.workflowTab === 'documents' ? (
          store.selectedMatter?.id ? (
            <DocumentsColumn />
          ) : (
            <EmptyDocumentsPlaceholder />
          )
        ) : null}
      </div>
    </div>
  )
})

export default Workflow
