import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom'

import ChatApp from './ChatApp'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/chat" element={<ChatApp />} />
        <Route path="*" element={<Navigate to="/chat" />} />
      </Routes>
    </Router>
  )
}

export default App
