import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'

import MemoModalAiDisclaimer from './MemoModalAiDisclaimer'
import MemoModalAiTooltip from './MemoModalAiTooltip'
import { hideModal } from '../../../actions/modalActions'
import { useStore } from '../../../useStore'
import AlexiLogo from '../../assets/alexiLogo'
import InfoIconSmall from '../../assets/InfoIconSmall'
import MemoDocument from '../../assets/MemoDocument'
import XIcon from '../../assets/XIcon'
import Tooltip from '../../shared/Tooltip'
import InstantMemoDownloadLink from '../InstantMemoDownloadLink'

function memoJurisdictionInfo(memo) {
  switch (memo.country_code) {
    case 'CA':
      return (
        <p>
          <strong className="font-medium">Province/Territory: </strong>
          {memo?.province_territory}
        </p>
      )
    case 'US':
      return (
        <>
          <p>
            <strong className="font-medium">Jurisdiction: </strong>
            {memo?.us_jurisdiction}
          </p>
          <p>
            <strong className="font-medium">
              {memo?.us_jurisdiction === 'Federal' ? 'Forum: ' : 'State: '}
            </strong>
            {memo?.province_territory}
          </p>
        </>
      )
    default:
      return null
  }
}

const MemoViewModal = observer(() => {
  const store = useStore()

  const aiInfoBoxRef = useRef(null)
  const backdropRef = useRef(null)

  function scrollToInfoBox() {
    aiInfoBoxRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  function backdropClick(event) {
    // Only dismiss the modal if the click is actually on the dark backdrop
    if (event.target === backdropRef.current) {
      hideModal(store)
    }
  }

  const tooltipContents = (
    <MemoModalAiTooltip scrollToInfoBox={scrollToInfoBox} />
  )

  const instant_memo = store.modal.data.instant_memo

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={backdropClick}
      ref={backdropRef}
    >
      <div className="flex flex-col bg-white rounded-lg shadow-lg max-w-4xl h-5/6 w-full overflow-hidden">
        <div className="shrink flex items-center bg-white p-6 border-b border-gray-300">
          <div className="p-2 bg-bgCol-neutral-secondary rounded-md flex items-center justify-center">
            <MemoDocument />
          </div>

          <div className="flex-grow ml-4 mr-4 min-w-0">
            <h2 className="text-lg font-semibold text-txt-default-default whitespace-nowrap overflow-hidden text-ellipsis leading-6">
              {instant_memo?.title}
            </h2>
            <div className="flex items-center text-xs">
              <p className="mr-1 text-txt-default-secondary">Created by</p>

              <AlexiLogo width={12} height={12} />
              <p className="text-txt-brand-default px-0.5">alexi</p>
              <Tooltip tooltipContents={tooltipContents}>
                <div className="h-3 w-3 flex items-center">
                  <InfoIconSmall />
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="flex-shrink-0 flex items-center space-x-2">
            <Tooltip tooltipContents="Download">
              <InstantMemoDownloadLink memoId={instant_memo?.id} />
            </Tooltip>
            <button
              onClick={() => hideModal(store)}
              className="m-2 text-gray-400 hover:text-gray-600 w-6 h-6"
            >
              <XIcon classNames="w-[22px] h-[22px]" />
            </button>
          </div>
        </div>

        <div className="grow px-6 py-4 overflow-y-auto scrollbar-visible">
          {store.modal.isLoading ? (
            <div>Loading...</div>
          ) : store.modal.errorMessage ? (
            <div>Error: {store.modal.errorMessage}</div>
          ) : (
            <>
              <div className="text-base text-txt-default-default">
                <p>
                  <strong className="font-medium">Memo To: </strong>{' '}
                  {instant_memo.memo_to}
                </p>
                {memoJurisdictionInfo(instant_memo)}
                <p>
                  <strong className="font-medium">Date: </strong>{' '}
                  {instant_memo.date}
                </p>
              </div>
              <div className="no-tailwind pb-2 viewer-content">
                <div
                  dangerouslySetInnerHTML={{
                    __html: store.modal.data.instant_memo_html,
                  }}
                />
              </div>
              <div ref={aiInfoBoxRef}>
                <MemoModalAiDisclaimer />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
})

export default MemoViewModal
