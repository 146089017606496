import PropTypes from 'prop-types'
import React from 'react'

import WarningIcon from '../assets/WarningIcon'

const ActivityItemHeader = ({ activity, description }) => {
  const utcTimestamp = activity.created_at_formatted
  const localDate = new Date(utcTimestamp)
  const formattedDate = localDate.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true, // This ensures the 12-hour clock and AM/PM format
  })
  const isFailedActivity =
    activity.activity_item.status === 'FAILED' ||
    activity.activity_item.status === 'INVALID'

  return (
    <div className="flex items-center">
      {/* Time */}
      <p className="text-xs text-txt-default-secondary font-normal leading-tight overflow-hidden text-nowrap">
        {formattedDate}
      </p>
      <span className="mx-2 align-middle text-txt-default-secondaryHover">
        •
      </span>
      {/* Activity Type */}
      <p
        className={`text-xs font-normal leading-tight truncate overflow-hidden ${
          isFailedActivity
            ? 'text-txt-danger-default'
            : 'text-txt-default-secondary'
        }`}
      >
        {description}
      </p>
      <p className="ml-1">{isFailedActivity && <WarningIcon />}</p>
    </div>
  )
}

ActivityItemHeader.propTypes = {
  activity: PropTypes.shape({
    created_at_formatted: PropTypes.string.isRequired,
    activity_item: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  description: PropTypes.string.isRequired,
}

export default ActivityItemHeader
