import PropTypes from 'prop-types'
import React from 'react'

import CancelButton from './CancelButton'
import SubmitButton from './SubmitButton'

const Step2 = ({ inputValue, setInputValue, handleSubmit, handleCancel }) => (
  <div className="my-4">
    <textarea
      className="w-full p-2 border rounded-lg"
      placeholder="Enter your research question here."
      rows="4"
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
    />
    <div className="flex justify-end space-x-2 mt-2">
      <CancelButton handleCancel={() => handleCancel(2)} />
      <SubmitButton
        disabled={inputValue.length === 0}
        handleSubmit={() => handleSubmit(2, { research_question: inputValue })}
      />
    </div>
  </div>
)

Step2.propTypes = {
  inputValue: PropTypes.string,
  setInputValue: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
}

export default Step2
