import dayjs from 'dayjs'
import { runInAction, action } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import CreateIcon from './assets/CreateIcon'
import CreateIconDisabled from './assets/CreateIconDisabled'
import FilterIcon from './assets/FilterIcon'
import SortIcon from './assets/SortIcon'
import FilterModal from './FilterModal'
import MattersList from './matter/MattersList'
import NoResultsFound from './NoResultsFound'
import Tooltip from './shared/Tooltip'
import SortModal from './SortModal'
import addFlash from '../actions/AddFlash'
import { setInputValue } from '../actions/InputValueActions'
import { setMatters, setSelectedMatter } from '../actions/matterActions'
import { resetInput } from '../actions/ResetInput'
import { deleteMatter, undoDeleteMatter } from '../apis/mattersApi'
import { getMessageObject } from '../LocalStorageUtil'
import { rollbarConfig } from '../rollbarConfig'
import { useStore } from '../useStore'

const setPageTitle = (store) => {
  document.title = store.selectedMatter
    ? `${store.selectedMatter.title} - Alexi`
    : 'Home - Alexi'
}

const Sidebar = observer(() => {
  const store = useStore()
  setPageTitle(store)

  const [isSortingDropdownVisible, setIsSortingDropdownVisible] =
    useState(false)
  const [isFilterDropdownVisible, setIsFilterDropdownVisible] = useState(false)

  const handleNewMatter = async () => {
    setSelectedMatter(store, null)
    resetInput(store)

    runInAction(() => {
      store.activities = []
      store.inputRef?.focus()
      store.isInputFocused = true
    })
  }

  const handleUndoDeleteMatterClick = action((matter) => {
    undoDeleteMatter(matter.id)
      .then(() => {
        setMatters(store)
        store.flashes = []
        addFlash(store, 'success', `Action Reversed.`)
      })
      .catch((error) => {
        rollbarConfig(store)?.error(error)
      })
  })

  const handleDeleteClick = async (matter) => {
    deleteMatter(matter.id)
      .then(() => {
        addFlash(store, 'notice', `${matter.title} was successfully deleted!`, {
          subType: 'deleteMatter',
          matter,
          onDeleteMatterClick: handleUndoDeleteMatterClick,
        })
        store.matters = store.matters.filter(
          (aMatter) => aMatter.id !== matter.id
        )
      })
      .catch((error) => {
        rollbarConfig(store)?.error(error)
      })
  }

  const handleMatterClick = action((matter) => {
    if (store.selectedMatter?.id !== matter.id) {
      setSelectedMatter(store, matter)
      setInputValue(store, getMessageObject(matter.id))
      setPageTitle(store)
    }
  })

  const handleSort = (sortParams) => {
    runInAction(() => {
      store.sortParams = sortParams
    })
    setMatters(store)
    setIsSortingDropdownVisible(false)
  }

  const handleFilter = (filterParams) => {
    runInAction(() => {
      store.filterParams = filterParams
    })
    setMatters(store)
    setIsFilterDropdownVisible(false)
  }

  const groupMatters = (matters) => {
    const now = dayjs()
    const groupOrder =
      store.sortParams?.get('sortBy').includes('_at') &&
      store.sortParams?.get('sortOrder') === 'asc'
        ? ['Older', 'This Month', 'This Week', 'Yesterday', 'Today']
        : ['Today', 'Yesterday', 'This Week', 'This Month', 'Older']

    const groups = groupOrder.reduce((acc, group) => {
      acc[group] = []
      return acc
    }, {})
    matters.forEach((matter) => {
      const sortKey =
        store.sortParams?.get('sortBy') === 'created_at'
          ? 'created_at'
          : 'updated_at'
      const dateAt = dayjs(matter[sortKey])
      const groupKey = dateAt.isSame(now, 'day')
        ? 'Today'
        : dateAt.isSame(now.subtract(1, 'day'), 'day')
          ? 'Yesterday'
          : dateAt.isAfter(now.subtract(1, 'week'))
            ? 'This Week'
            : dateAt.isAfter(now.subtract(1, 'month'))
              ? 'This Month'
              : 'Older'

      groups[groupKey].push(matter)
    })

    return groups
  }

  const groupedMatters = groupMatters(store.matters)

  return (
    <div className="bg-white w-64 border-r flex flex-col h-full">
      <div className="sticky top-0 bg-white z-10 p-2 py-4">
        <div className="flex items-center justify-between px-2 relative">
          <div className="text-lg font-semibold">Matters</div>
          <div className="flex space-x-4">
            <Tooltip tooltipContents="Filter">
              <button
                className="text-xl"
                onClick={() =>
                  setIsFilterDropdownVisible(!isFilterDropdownVisible)
                }
              >
                <FilterIcon
                  stroke={store.filterParams ? '#5133EA' : '#1B212D'}
                />
                {store.filterParams && (
                  <span className="absolute top-0 left-4 mt-0.5 mr-0.5 h-2 w-2 rounded-full bg-bgCol-success-default opacity-75"></span>
                )}
              </button>
            </Tooltip>

            <Tooltip tooltipContents="Sort">
              <button
                className="text-xl relative"
                onClick={() =>
                  setIsSortingDropdownVisible(!isSortingDropdownVisible)
                }
              >
                <SortIcon stroke={store.sortParams ? '#5133EA' : '#1B212D'} />
                {store.sortParams && (
                  <span className="absolute top-0 left-4 mt-0.5 mr-0.5 h-2 w-2 rounded-full bg-bgCol-success-default opacity-75"></span>
                )}
              </button>
            </Tooltip>

            {store.selectedMatter === null ? (
              <button
                className="bg-bgCol-disabled-default p-1.5 rounded hover:cursor"
                disabled
              >
                <CreateIconDisabled />
              </button>
            ) : (
              <Tooltip tooltipContents="New matter">
                <button
                  className="bg-bgCol-brand-default p-1.5 rounded"
                  onClick={handleNewMatter}
                >
                  <CreateIcon />
                </button>
              </Tooltip>
            )}
          </div>
          {isSortingDropdownVisible && (
            <SortModal
              onSort={handleSort}
              onClose={() => {
                setIsSortingDropdownVisible(false)
              }}
              sortParams={store.sortParams}
            />
          )}
          {isFilterDropdownVisible && (
            <FilterModal
              onFilter={handleFilter}
              onClose={() => {
                setIsFilterDropdownVisible(false)
              }}
              filterParams={store.filterParams}
            />
          )}
        </div>
      </div>
      <div className="overflow-y-auto flex-grow">
        {store.matters.length > 0
          ? Object.keys(groupedMatters).map((group) => (
              <MattersList
                key={group}
                group={group}
                groupTitle={group}
                matters={groupedMatters[group]}
                handleMatterClick={handleMatterClick}
                handleDeleteClick={handleDeleteClick}
                selectedMatter={store.selectedMatter}
              />
            ))
          : store.filterParams !== undefined && <NoResultsFound />}
      </div>
    </div>
  )
})

export default Sidebar
