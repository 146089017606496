import Hotjar from '@hotjar/browser'
import { ErrorBoundary, Provider } from '@rollbar/react'
import { toJS } from 'mobx'
import React from 'react'
import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'

import App from './App'
import FlashMessages from './FlashMessages'
import getManifest from '../actions/getManifest'
import { fetchUserInfo } from '../apis/userApi'
import { connectToUserChannel } from '../channels/user_channel'
import { rollbarConfig } from '../rollbarConfig'
import Store from '../Store'
import StoreContext from '../StoreContext'

const store = new Store()

const loadUserInfo = async () => {
  try {
    store.user = await fetchUserInfo(store.credentials.customerAppUrl)
    connectToUserChannel(store)
  } catch (error) {
    console.error('Failed to fetch user info on initial load:', error)
  }
}

const getCredentials = () => {
  store.credentials = window.__INITIAL_DATA__ || {}
}

const devSetup = () => {
  window._store = store
  window.toJS = toJS
}

const prodSetup = () => {
  if (store.credentials.hotjarId && store.credentials.hotjarVersion) {
    Hotjar.init(store.credentials.hotjarId, store.credentials.hotjarVersion)

    Hotjar.identify(store?.user?.id.toString(), {
      email: store?.user?.email,
      name: store?.user?.full_name,
      enterprise_id: store?.user?.enterprise?.id,
      enterprise_name: store?.user?.enterprise?.name,
      country: store?.user?.country?.common_name,
      created_at: store?.user?.created_at,
    })
  }

  if (store.credentials.googleAnalyticsId)
    TagManager.initialize({
      gtmId: store.credentials.googleAnalyticsId,
    })
}

document.addEventListener('DOMContentLoaded', async () => {
  getCredentials()

  await loadUserInfo()
  await getManifest(store)

  process.env.NODE_ENV === 'development' ? devSetup() : prodSetup()

  const root = createRoot(
    document.body.appendChild(document.createElement('div'))
  )

  let rollbar = rollbarConfig(store)

  if (!store.user.enterprise.is_on_max_chat_app) return

  root.render(
    <StoreContext.Provider value={store}>
      {rollbar ? (
        <Provider instance={rollbar}>
          <ErrorBoundary>
            <FlashMessages />
            <App />
          </ErrorBoundary>
        </Provider>
      ) : (
        <ErrorBoundary>
          <FlashMessages />
          <App />
        </ErrorBoundary>
      )}
    </StoreContext.Provider>
  )
})
