import React from 'react'

import { submitMessage } from '../../actions/chatInputActions'
import { setInputValue } from '../../actions/InputValueActions'
import { setNewMatter } from '../../actions/matterActions'
import { createMatter } from '../../apis/mattersApi'
import { useStore } from '../../useStore'

export const InitialChatSuggestions = () => {
  const [suggestionClicked, setSuggestionClicked] = React.useState(false)

  const suggestions = [
    {
      label: 'Get an answer to a legal question',
      input: 'Can you help me answer a legal question?',
    },
    {
      label: 'Generate a research memo',
      input: 'Can you help me generate a research memo?',
    },
    {
      label: 'Ask a question about a document',
      input: 'Can you help me answer a question about a document?',
    },
    {
      label: 'Summarize a document',
      input: 'Can you help me summarize a document?',
    },
    {
      label: 'Draft a document',
      input: 'Can you help me draft a document?',
    },
  ]

  if (suggestionClicked) return null

  return (
    suggestions.length > 0 && (
      <div className="flex flex-wrap gap-y-4 gap-x-2.5 pb-4">
        {suggestions.map((suggestion, index) => (
          <SuggestionPill
            key={index}
            label={suggestion.label}
            input={suggestion.input}
            setSuggestionClicked={setSuggestionClicked}
          />
        ))}
      </div>
    )
  )
}

const SuggestionPill = ({
  label,
  input,
  setSuggestionClicked,
}: {
  label: string
  input: string
  setSuggestionClicked: (value: boolean) => void
}) => {
  const store = useStore()

  const handleSuggestionClick = async () => {
    setSuggestionClicked(true)

    setInputValue(store, input)

    if (!store.selectedMatter?.id)
      setNewMatter(store, await createMatter(store))

    submitMessage(store)
  }

  return (
    <button
      className="px-4 py-2 rounded-[32px] border border-brd-brand-default hover:bg-bgCol-brand-secondary"
      onClick={handleSuggestionClick}
    >
      <span className="text-sm text-brd-brand-default ">{label}</span>
    </button>
  )
}
