import { observer } from 'mobx-react-lite'
import React from 'react'

import AttachmentItemGroup from './AttachmentItemGroup'
import AttachmentItemSingle from './AttachmentItemSingle'
import EmptyActivitiesPlaceholder from './EmptyActivitiesPlaceholder'
import MemoItem from './MemoItem'
import SummaryDocItem from './SummaryDocItem'
import { useStore } from '../../useStore'

function renderActivityItem(activityOrGroup) {
  switch (activityOrGroup.activity_item_type) {
    case 'AttachmentActivityGroup':
      return <AttachmentItemGroup activities={activityOrGroup.items} />
    case 'MemoActivityItem':
      return <MemoItem activity={activityOrGroup} />
    case 'AttachmentActivityItem':
      return <AttachmentItemSingle activity={activityOrGroup} />
    case 'SummaryDocActivityItem':
      return <SummaryDocItem activity={activityOrGroup} />
    default:
      return null
  }
}

const ActivitiesColumn = observer(() => {
  const store = useStore()

  return (
    <div className="pb-4 pr-2">
      {Object.keys(store.groupedActivities).length === 0 ? (
        <EmptyActivitiesPlaceholder />
      ) : (
        // Grouped by date
        Object.keys(store.groupedActivities).map((date) => (
          <div key={date} className="mb-8">
            {/* Date */}
            <p className="text-sm mx-7 font-medium text-txt-default-default leading-tight mb-2">
              {new Date(date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                timeZone: 'UTC',
              })}
            </p>
            {store.groupedActivities[date].map((activityOrGroup, index) => {
              return (
                <div key={index}>{renderActivityItem(activityOrGroup)}</div>
              )
            })}
          </div>
        ))
      )}
    </div>
  )
})

export default ActivitiesColumn
