import React from 'react'

import SearchIcon from './assets/SearchIcon'

const NoResultsFound = () => {
  return (
    <div className="flex flex-col items-center justify-center p-7">
      <p className="text-gray-500 text-lg mb-6">No matching results found.</p>
      <div className="w-[64px] h-[10px]  bg-[#F2F2F2] relative rounded-full left-[-4rem]  mt-[12px]"></div>

      <div className="relative mt-4">
        {/* Bottom Shape (rotated) */}
        <div className="w-[208px] h-[48px] bg-[#F2F2F2] rounded-full absolute top-[50px] left-1/2 transform -translate-x-1/2 rotate-[-7.4deg] shadow-md">
          <div className="w-[184px] h-[24px] bg-[#FFFFFF] relative rounded-full mx-auto mt-[12px]">
            <div className="w-[160px] h-[8px]  bg-[#F2F2F2] absolute rounded-full ml-4 mb-12 mt-[8px]"></div>
          </div>
        </div>

        {/* Top Shape */}
        <div className="w-[208px] h-[48px] bg-[#FAFAFA] rounded-full absolute top-0 left-1/2 transform -translate-x-1/2 shadow-md">
          <div className="w-[184px] h-[24px] bg-[#FFFFFF] relative rounded-full mx-auto mt-[12px]">
            <div className="w-[160px] h-[8px]  bg-[#F2F2F2] absolute rounded-full ml-4 mb-12 mt-[8px]"></div>
          </div>
        </div>

        {/* Magnifying Glass */}
        <div className="absolute top-[26px] left-1/2 transform -translate-x-1/2 w-14 h-14 rounded-full flex items-center justify-center z-10">
          <SearchIcon className="w-8 h-8 text-white" />
        </div>
      </div>
    </div>
  )
}

export default NoResultsFound
