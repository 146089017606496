import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

import ArrowRightIcon from './assets/ArrowRightIcon'
import XIcon from './assets/XIcon'
import filterOptions from './filterOptions.json'

// Utility function to initialize state based on filter options and params
const initializeState = (filterOptions, filterParams, defaultValue) => {
  const initialState = {}
  for (const key in filterOptions) {
    initialState[key] = filterParams?.get(key) || defaultValue
  }
  return initialState
}

const FilterModal = ({ onFilter, onClose, filterParams }) => {
  const [selectedOptions, setSelectedOptions] = useState(() =>
    initializeState(filterOptions, filterParams, null)
  )

  const [collapsedSections, setCollapsedSections] = useState(() =>
    initializeState(filterOptions, null, true)
  )

  useEffect(() => {
    setSelectedOptions(initializeState(filterOptions, filterParams, null))
  }, [filterParams])

  const handleOptionChange = (category, value) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [category]: value,
    }))
  }

  const handleFilterChange = () => {
    const filterOptionsMap = new Map()

    // Iterate over selectedOptions and add them to filterParams if they are not null
    Object.entries(selectedOptions).forEach(([key, value]) => {
      if (value !== null) {
        filterOptionsMap.set(key, value)
      }
    })

    // Pass the filterOptions to onFilter
    onFilter(filterOptionsMap)
  }

  const handleClearAll = () => {
    const clearedOptions = initializeState(filterOptions, null, null)
    setSelectedOptions(clearedOptions)
    onFilter(undefined)
  }

  const handleClearCategory = (categoryKey) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [categoryKey]: null,
    }))
  }

  const toggleCollapse = (categoryKey) => {
    setCollapsedSections((prevState) => ({
      ...prevState,
      [categoryKey]: !prevState[categoryKey],
    }))
  }

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div className="absolute left-40 top-40 mt-2 border w-80 bg-white shadow-md rounded-md">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-lg font-semibold">Filters</h2>
          <div onClick={onClose}>
            <XIcon />
          </div>
        </div>
        <div className="p-4 gap-4">
          {Object.keys(filterOptions).map((categoryKey) => (
            <div className="mb-4 text-txt-default-secondary" key={categoryKey}>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleCollapse(categoryKey)}
              >
                <h3 className="text-sm font-semibold flex items-center">
                  <span
                    className={`mr-2 transform inline-block ${
                      collapsedSections[categoryKey] ? '' : 'rotate-90'
                    }`}
                  >
                    <ArrowRightIcon />
                  </span>
                  {filterOptions[categoryKey].label}
                </h3>
                {selectedOptions[categoryKey] && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation() // Prevent collapse toggle on clear
                      handleClearCategory(categoryKey)
                    }}
                    className="text-txt-danger-default text-sm"
                  >
                    Clear
                  </button>
                )}
              </div>
              {!collapsedSections[categoryKey] && (
                <div className="pl-4 pt-2">
                  {filterOptions[categoryKey].options.map((option) => (
                    <div key={option.value} className="mb-2">
                      <label className="flex items-center text-sm font-normal">
                        <input
                          type="radio"
                          name={categoryKey}
                          value={option.value}
                          onChange={() =>
                            handleOptionChange(categoryKey, option.value)
                          }
                          checked={
                            selectedOptions[categoryKey] === option.value
                          }
                          className="mr-2"
                        />
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
          <div className="flex flex-row justify-between items-center mt-4">
            {Object.values(selectedOptions).some((value) => value !== null) && (
              <a
                onClick={handleClearAll}
                className="text-txt-danger-default cursor-pointer text-sm"
              >
                Clear all
              </a>
            )}
            <div className="flex-grow"></div>
            <button
              onClick={handleFilterChange}
              className="bg-bgCol-brand-default text-white py-2 px-4 rounded-md hover:bg-bgCol-brand-defaultHover disabled:bg-bgCol-disabled-default disabled:text-txt-disabled-default"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

FilterModal.propTypes = {
  onFilter: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  filterParams: PropTypes.instanceOf(Map),
}

export default FilterModal
