import PropTypes from 'prop-types'
import React, { useState } from 'react'

import XIcon from './assets/XIcon'
import SelectInput from './shared/SelectInput'

const SortModal = ({ onSort, onClose, sortParams }) => {
  const [sortBy, setSortBy] = useState(
    sortParams?.get('sortBy') || 'updated_at'
  )
  const [sortOrder, setSortOrder] = useState(
    sortParams?.get('sortOrder') || 'desc'
  )
  const sortOptions = {
    assigned_name: 'Name',
    file_number: 'File Number',
    created_at: 'Date Created',
    updated_at: 'Date Modified',
  }
  const orderOptions = {
    asc: 'Ascending',
    desc: 'Descending',
  }

  const handleSortChange = () => {
    const newSortParams = new Map([
      ['sortBy', sortBy],
      ['sortOrder', sortOrder],
    ])
    onSort(newSortParams)
  }
  const handleClearAll = () => {
    setSortBy('updated_at')
    setSortOrder('desc')
    onSort(undefined)
  }

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div className="absolute left-40 top-40 mt-2 border w-80 bg-white shadow-md rounded-md">
        <div className="flex justify-between items-center p-4">
          <h2 className="text-lg font-semibold">Sort by</h2>
          <div onClick={onClose}>
            <XIcon />
          </div>
        </div>
        <div className="border-b"></div>
        <div className="p-4 gap-4">
          <div className="mb-4">
            <SelectInput
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              options={sortOptions}
            />
          </div>
          <div className="mb-4">
            <SelectInput
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
              options={orderOptions}
            />
          </div>
          <div className="flex flex-row justify-between items-center">
            <a
              onClick={handleClearAll}
              className="text-txt-danger-default cursor-pointer text-sm"
            >
              Clear all
            </a>
            <button
              onClick={handleSortChange}
              className="bg-bgCol-brand-default text-white py-2 px-4 rounded-md hover:bg-bgCol-brand-defaultHover"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

SortModal.propTypes = {
  onSort: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  sortParams: PropTypes.instanceOf(Map),
}

export default SortModal
