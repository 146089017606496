import { toJS, runInAction } from 'mobx'
import PropTypes from 'prop-types'

import { createActivityChannelSubscription } from './activity_channel'
import consumer from './consumer'
import addFlash from '../actions/AddFlash'
import { setChatResponseLoading } from '../actions/chatResponseLoadingActions'
import {
  getLastProcessedId,
  initializeLastProcessedIds,
  setLastProcessedIds,
} from '../actions/lastProcessedIdsStoreActions'
import {
  deleteMatterChannel,
  getMatterChanel,
  setMatterChannel,
  setActivityChannel,
  deleteActivityChannel,
  getActivityChannel,
} from '../actions/matterChannelsActions'
import {
  addMessageToQueue,
  getMessageQueue,
  getNextMessageFromQueue,
  initializeMessageQueue,
  setMessageQueue,
} from '../actions/messageQueuesStoreActions'
import {
  addMessage,
  addToExistingMessage,
  createNewMessage,
  findMessage,
  initializeMessages,
} from '../actions/messagesActions'
import { rollbarConfig } from '../rollbarConfig'

const handleEndOfStream = (store, matterId, messageId) => {
  const lastProcessedId = getLastProcessedId(store, matterId)
  const messageQueue = getMessageQueue(store, matterId)

  if (lastProcessedId === 0 && messageQueue.length === 0) {
    // If we never processed any chunks, then we are in a serious error state
    reportStreamSyncError(
      store,
      matterId,
      messageId,
      'Stream Sync Error: no received chunks'
    )
    addFlash(store, 'error', 'Something went wrong. Please refresh the page.')
    setChatResponseLoading(store, store.selectedMatter.id, false)
  } else if (lastProcessedId > 10 && messageQueue.length > 0) {
    // If we have left-over chunks in the queue but we received at least a few chunks, then we are in an error state, but perhaps not a serious one
    reportStreamSyncError(
      store,
      matterId,
      messageId,
      'Stream Sync Error: stream ended with leftover chunks'
    )
  }
  resetStreamState(store, matterId)
  runInAction(() => {
    store.isStreaming[matterId] = false
  })
}

const resetStreamState = (store, matterId) => {
  setLastProcessedIds(store, matterId, 0)
  setMessageQueue(store, matterId, [])
}

const reportStreamSyncError = (store, matterId, messageId, errorMessage) => {
  const message = findMessage(store, matterId, messageId)
  const lastProcessedId = getLastProcessedId(store, matterId)
  const messageQueue = getMessageQueue(store, matterId)

  console.error(errorMessage)

  rollbarConfig(store)?.error('Chat Stream Synchronization error', {
    user: toJS(store.user),
    matterId: store.selectedMatter?.id,
    lastProcessedId,
    messageQueue: toJS(messageQueue),
    message: toJS(message),
    errorMessage,
  })
}

const handleChatMessageChunk = (store, message, matterId) => {
  if (message.matter_id !== matterId) return

  const messageContent = message.content.parts.join('')
  const messageAdditionalContent = message.response_additional_context || null
  const currentType = message.current_type

  if (messageContent === 'END_OF_STREAM') {
    // Tricky situation here. WS events may arrive out of order, so we may receive the end of stream message while there are still message chunks left to receive. This case is likely to be common. We do not wish to complain about an error until a few seconds after the end of stream chunk arrived.
    setTimeout(() => handleEndOfStream(store, matterId, message.id), 250)
    return
  }

  initializeLastProcessedIds(store, matterId)
  initializeMessageQueue(store, matterId)
  initializeMessages(store, matterId)

  addMessageToQueue(store, matterId, message)

  processMessageQueue(store, matterId, currentType, messageAdditionalContent)
}

const processMessageQueue = (
  store,
  matterId,
  currentType,
  messageAdditionalContent
) => {
  const messageQueue = getMessageQueue(store, matterId)

  let i = 0

  while (i < 100) {
    // In case our base cases fail in some way, use this counter to break the loop
    i += 1
    const nextExpectedChunkId = getLastProcessedId(store, matterId) + 1

    if (messageQueue.length === 0) {
      return
    }

    // We rely on the message queue storing messages sorted by chunk_id. If we fail to sort the array after adding to it, we may get stuck unable to append if the first message in the queue is not the next to be appended.
    if (messageQueue[0].chunk_id !== nextExpectedChunkId) {
      return
    }

    const nextMessage = getNextMessageFromQueue(store, matterId)
    const content = nextMessage.content.parts.join('')

    let existingMessage = findMessage(store, matterId, nextMessage.id)

    if (!existingMessage) {
      createNewMessage(
        store,
        nextMessage,
        matterId,
        store.user.id,
        currentType,
        content,
        messageAdditionalContent
      )

      setChatResponseLoading(store, matterId, false)
    } else {
      addToExistingMessage(existingMessage, content)
    }

    setLastProcessedIds(store, matterId, nextMessage.chunk_id)
  }
}

const handleChatMessage = (store, message, matterId) => {
  setChatResponseLoading(store, matterId, false)
  addMessage(store, matterId, message)
}

const connectToMatterChannel = (store, matterId) => {
  if (!matterId) return

  unsubscribeFromMatterChannel(store, matterId)

  setMatterChannel(
    store,
    matterId,
    createMatterChannelSubscription(store, matterId)
  )
  setActivityChannel(
    store,
    matterId,
    createActivityChannelSubscription(store, matterId)
  )
}

const unsubscribeFromMatterChannel = (store, matterId) => {
  const existingChannel = getMatterChanel(store, matterId)
  const existingActivityChannel = getActivityChannel(store, matterId)

  if (existingChannel) {
    existingChannel.unsubscribe()

    deleteMatterChannel(store, matterId)
  }
  if (existingActivityChannel) {
    existingActivityChannel.unsubscribe()
    deleteActivityChannel(store, matterId)
  }
}

const createMatterChannelSubscription = (store, matterId) => {
  return consumer.subscriptions.create(
    { channel: 'MatterChannel', matter_id: matterId },
    {
      received(data) {
        handleReceivedData(store, data, matterId)
      },
    }
  )
}

const handleReceivedData = (store, data, matterId) => {
  try {
    const parsedChunk = JSON.parse(data)

    switch (parsedChunk.message_type) {
      case 'chat_message_chunk':
        handleChatMessageChunk(store, parsedChunk.message, matterId)
        break
      case 'chat_message':
        handleChatMessage(store, parsedChunk.message, matterId)
        break
      default:
        console.error('Unknown message type:', parsedChunk.message_type)

        rollbarConfig(store)?.error('Unknown message type', { parsedChunk })

        addFlash(
          store,
          'error',
          'Something went wrong. Please refresh the page.'
        )
        setChatResponseLoading(store, store.selectedMatter.id, false)
    }
  } catch (e) {
    console.error('Failed to parse JSON chunk:', data, e)
    rollbarConfig(store)?.error(e)
  }
}

handleChatMessageChunk.propTypes = {
  store: PropTypes.object.isRequired,
  message: PropTypes.shape({
    id: PropTypes.string,
    content: PropTypes.shape({
      parts: PropTypes.arrayOf(PropTypes.string),
      content_type: PropTypes.string,
    }),
    current_type: PropTypes.string,
    response_additional_context: PropTypes.object,
    status: PropTypes.string,
    chat_thread_id: PropTypes.string,
    chunk_id: PropTypes.number,
    matter_id: PropTypes.string,
  }).isRequired,
}

export { connectToMatterChannel }
