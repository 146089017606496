import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import DocumentDeleteForm from './DocumentDeleteForm'
import EmptyDocumentsPlaceholder from './EmptyDocumentsPlaceholder'
import { setFetchedDocuments } from '../../actions/fileActions'
import { downloadFile } from '../../apis/filesApi'
import { useStore } from '../../useStore'
import DocumentDefault from '../assets/DocumentDefault'
import DocumentDOCX from '../assets/DocumentDOCX'
import DocumentJPG from '../assets/DocumentJPG'
import DocumentPDF from '../assets/DocumentPDF'
import DocumentPPT from '../assets/DocumentPPT'
import DownloadIcon from '../assets/DownloadIcon'
import TrashIcon from '../assets/TrashIcon'
import Tooltip from '../shared/Tooltip'

const DocumentsColumn = () => {
  const store = useStore()
  const docs = store.documents
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  useEffect(() => {
    if (store.workflowTab === 'documents' && store.selectedMatter?.id) {
      setFetchedDocuments(store)
    }
  }, [store, store.workflowTab, store.selectedMatter?.id])

  const handleDownload = (doc) => {
    downloadFile(store, doc)
  }

  const getFileComponent = (doc) => {
    const fileType = doc.content_type.toLowerCase()

    switch (fileType) {
      case 'application/pdf':
        return <DocumentPDF />
      case 'image/png':
      case 'image/jpeg':
      case 'image/jpg':
        return <DocumentJPG />
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/msword':
        return <DocumentDOCX />
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'application/vnd.ms-powerpoint':
        return <DocumentPPT />
      default:
        return <DocumentDefault />
    }
  }

  return Array.isArray(docs) && docs.length > 0 ? (
    <div className="h-full overflow-y-auto">
      <div className="grid grid-cols-2 gap-2 p-1 pb-6">
        {docs.map((doc, index) => (
          <div
            key={index}
            className="group relative flex flex-col items-center text-center py-4 px-6 rounded-lg hover:shadow-md transition-shadow duration-300 cursor-pointer hover:bg-bgCol-disabled-default"
          >
            <div className="mb-2">{getFileComponent(doc)}</div>
            <p className="text-sm font-medium truncate max-w-full">
              {doc.filename}
            </p>
            <Tooltip tooltipContents={doc.filename}>
              <p className="text-xs text-txt-default-secondary">
                Generated short name
              </p>
            </Tooltip>

            {/* Action buttons */}
            <div className="absolute right-2 top-2 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <button onClick={() => handleDownload(doc)} className="my-2 pr-1">
                <DownloadIcon />
              </button>
              <button
                onClick={() => setIsDeleteModalOpen(true)}
                className="my-2 pr-1"
              >
                <TrashIcon />
              </button>
            </div>
            {isDeleteModalOpen && (
              <DocumentDeleteForm
                doc={doc}
                onClose={() => setIsDeleteModalOpen(false)}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  ) : (
    <EmptyDocumentsPlaceholder />
  )
}

export default observer(DocumentsColumn)
