import { action } from 'mobx'

export const getMatterChanel = (store, matterId) => {
  return store.matterChannels[matterId]
}

export const setMatterChannel = action((store, matterId, channel) => {
  store.matterChannels[matterId] = channel
})

export const deleteMatterChannel = action((store, matterId) => {
  delete store.matterChannels[matterId]
})

export const setActivityChannel = (store, matterId, channel) => {
  store.activityChannels = store.activityChannels || {}
  store.activityChannels[matterId] = channel
}

export const deleteActivityChannel = (store, matterId) => {
  if (store.activityChannels && store.activityChannels[matterId]) {
    delete store.activityChannels[matterId]
  }
}

export const getActivityChannel = (store, matterId) => {
  return store.activityChannels ? store.activityChannels[matterId] : null
}
