import React, { ReactNode, useState } from 'react'

import ChevronDownIcon from '../assets/ChevronDownIcon'
import ChevronRightIcon from '../assets/ChevronRightIcon'

interface AccordionProps {
  title: string
  body: string | ReactNode
  open: boolean
}

const Accordion = ({ title, body, open }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(open)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div>
      <div
        className="flex justify-start items-center cursor-pointer py-1.5"
        onClick={toggleAccordion}
      >
        <div className="flex flex-col">
          <div className="flex flex-row items-center">
            <div className="mr-1.5">
              {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </div>
            <p className="text-sm font-medium leading-default text-txt-brand-default">
              {title}
            </p>
          </div>
          {isOpen && (
            <div className="text-sm mx-[1.625rem] mt-2">
              {typeof body === 'string' ? <p>{body}</p> : body}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Accordion
