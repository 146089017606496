import axios from 'axios'

export const fetchStates = async (country_code) => {
  try {
    const response = await axios.get(
      `/api/client/v1/states?country_code=${country_code}`
    )
    return response.data // Ensure that this returns the correct data structure
  } catch (error) {
    console.error('Error fetching states:', error)
    throw error
  }
}
