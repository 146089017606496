import { observer } from 'mobx-react-lite'
import React from 'react'

import InitialChatHeader from './InitialChatHeader'
import SelectedChatHeader from './SelectedChatHeader'
import { useStore } from '../../useStore'

const ChatHeader = observer(() => {
  const store = useStore()

  return (
    <div>
      {store.selectedMatter?.id ? (
        <SelectedChatHeader
          selectedMatter={store.selectedMatter}
          user={store.user}
        />
      ) : (
        <InitialChatHeader user={store.user} />
      )}
    </div>
  )
})

export default ChatHeader
