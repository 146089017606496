import React from 'react'

const DocumentDefault = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="102"
    viewBox="0 0 80 102"
    fill="none"
  >
    <path
      d="M67.6671 101H12.1116C9.16473 101 5.08374 101.085 3 99.0009C0.916262 96.9172 1.00047 92.8362 1.00047 89.8894V12.1116C1.00047 9.16473 0.91627 5.08374 3.00001 3.00001C5.08375 0.91627 9.16473 1.00047 12.1116 1.00047H51.0005L78.7782 28.7782V89.8894C78.7782 92.8362 78.8624 96.9172 76.7787 99.0009C74.695 101.085 70.614 101 67.6671 101Z"
      fill="#FAFAFA"
      stroke="#F2F2F2"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51 1V23.2222C51 24.6956 51.5853 26.1087 52.6272 27.1506C53.6691 28.1925 55.0821 28.7778 56.5556 28.7778H78.7778"
      stroke="#F2F2F2"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default DocumentDefault
